<template>
    <!-- 导航 -->
    <page-head title="训练教案" />
    <!-- 列表 -->
    <template v-if="!loading">
        <template v-if="exerciseData.length > 0">
            <div class="mian">
                <exercise-item
                    v-for="(item, index) in exerciseData"
                    :key="item.id"
                    :item="item"
                    :index="index"
                    @click="details(item)"
                />
            </div>
            <!-- 翻页 -->
            <pagination-com
                class="pagination"
                :current-page="query.page"
                :total-page="totalPage"
                @changePage="changePage"
            />
        </template>
        <no-data v-else />
    </template>
</template>

<script>
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { getCurrentInstance, reactive, toRefs, onMounted, defineComponent, watch, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    name: "menu",
    setup() {

        const router = useRouter()
        const { proxy } = getCurrentInstance()
        const store = useStore();
        const { color } = proxy.$Config.UI_CONFIG

        const state = reactive({
            color,
            active: 0,
            query: {
                page: 1,
                pageSize: 10
            },
            exerciseData: [],
            totalPage: 0,
            loading: true,
        });
        watch(() => store.state.identifyData, (val, old) => {
            // console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                page: state.query.page,
                totalPage: state.totalPage,
                showList: state.exerciseData,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
                router: details
            })
        })
        const getData = async() => {
            state.loading = true;
            const {
                code,
                data: { list, pages }
            } = await proxy.$server.getExerciseList(state.query);
            state.loading = false;
            if (code === 200) {
                state.exerciseData = list;
                state.totalPage = pages;
            }
        };

        const changePage = (page) => {
            state.query.page = page
            getData()
        }

        const queryParams = computed(() => {
            return store.state.queryParams;
        });

        onMounted(() => {
            if(queryParams.value.page) {
                state.query.page = queryParams.value.page
            }
            getData()
        })

        onBeforeRouteLeave((to, from, next) => {
            console.log(to)
            let params = {
                page: state.query.page
            }
            if(to.name === 'exerciseDetails') {
                store.commit('setQueryParams', params);
            } else {
                params.page = 1
                store.commit('setQueryParams', params);
            }
            next()
        })

        const details = (row) => {
            router.push({
                path: '/exerciseList/details',
                query: {
                    id: row.id,
                },
            })
        }

        const tabClick = (index) => {
            state.active = index
        }

        return {
            ...toRefs(state),
            changePage,
            details,
            tabClick
        }
    }
})
</script>

<style scoped lang="scss">
.mian {
    display: grid;
    grid-template-columns: repeat(5, 272px);
    grid-template-rows: auto;
    justify-content: space-between;
    margin: 40px 0 74px 67px;
    width: 1440px;
    height: 709px;
}
.pagination {
    margin-left: 590px;
}
</style>